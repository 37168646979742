<template>
	<div class="login-box">
		<img class="login-box-cover-img" src="../assets/image/woyaoyou_bg_login@2x.png" />
		<div class="login-main">
			<div>
				<div class="text-center">
					<img v-if="siteInfo != null" style="width: 162px;" :src="siteInfo.logo_white_account_login" />
				</div>
				<div class="login-form-box">
					<a-tabs v-model="type">
						<a-tab-pane key="1" tab="账号密码登录">
							<div class="login-input-item flex alcenter space">
								<a-input v-model="form.mobile" prefix="手机号" :maxLength="11" class="mobile"></a-input>
							</div>
							<div class="login-input-item mt24 flex alcenter space">
								<a-input type="password" v-model="form.pwd" prefix="密码" class="mobile"></a-input>
							</div>
							<div class="mt32 login-btn">
								<a-button style="width: 100%;" @click="loginAct2" type="primary">立即登录</a-button>
							</div>
						</a-tab-pane>
						<a-tab-pane key="2" tab="验证码登录(注册)" force-render>
							<div class="login-input-item flex alcenter space">
								<a-input v-model="form.mobile" prefix="手机号" :maxLength="11" class="mobile"></a-input>
							</div>
							<div class="login-input-item mt24 flex alcenter space">
								<a-input v-model="form.code" class="code" :maxLength="6" prefix="验证码"></a-input>

								<countdown-djs :mobile="form.mobile" :code="code" :type="'login'"></countdown-djs>
							</div>
							<div class="mt32 login-btn">
								<a-button style="width: 100%;" @click="loginAct" type="primary">立即登录</a-button>
							</div>
						</a-tab-pane>
					</a-tabs>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import countdownDjs from '../components/countdown/djs.vue';
export default {
	components: {
		countdownDjs
	},
	data() {
		return {
			type: '1',
			loading: false,
			code: '',
			form: {
				mobile: '',
				code: '',
				pwd: '',
			},
			siteInfo: null
		}
	},
	created() {
		if (localStorage.getItem('account-token')) {
			this.$router.push('/mendian');
		} else {
			this.getSiteInfo();
			this.getCode();
		}
	},

	mounted() {
		//绑定事件
		window.addEventListener('keydown', this.keyDown);
	},
	methods: {
		getSiteInfo() {
			this.$http.api('platform/siteInfo').then(res => {
				this.siteInfo = res.site;
			}).catch(res => {
				console.log(res);
			});
		},
		getCode() {
			this.$http.api('platform/account/getCode', {

			}).then(res => {
				this.code = res.code;
			}).catch(res => {
				console.log(res);
			});

		},
		loginAct() {
			if (this.loading == true) return;
			this.loading = true;
			this.$http.api('platform/account/login', {
				mobile: this.form.mobile,
				code: this.form.code,
				token: this.code,
			}).then(res => {
				this.loading = false;
				localStorage.setItem('account-token', res.auth_token);
				this.$message.success('登录成功', 1, () => {
					this.$router.push('/mendian');
				})
			}).catch(res => {
				console.log(res);
				this.loading = false;
			})
		},
		loginAct2() {
			if (this.loading == true) return;
			this.loading = true;
			this.$http.api('platform/account/pwdLogin', {
				mobile: this.form.mobile,
				pwd: this.form.pwd,
			}).then(res => {
				this.loading = false;
				localStorage.setItem('account-token', res.auth_token);
				this.$message.success('登录成功', 1, () => {
					this.$router.push('/mendian');
				})
			}).catch(res => {
				console.log(res);
				this.loading = false;
			})
		},
		keyDown(e) {
			//如果是回车则执行登录方法
			if (e.keyCode == 13) {
				if (this.type == '1') {
					this.loginAct2();
				} else {
					this.loginAct();
				}

			}
		},
	},
	destroyed() {
		window.removeEventListener('keydown', this.keyDown, false);
	}
}
</script>

<style>
.login-box {
	width: 100vw;
	height: 100vh;
	background: linear-gradient(135deg, #4772FF 0%, #5851FF 100%);
	position: relative;
}

.login-main {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 2;
}

.login-box-cover-img {
	position: absolute;
	bottom: 0px;
	width: 100vw;
}

.login-form-box {
	width: 420px;
	height: 362px;
	background: #FFFFFF;
	box-shadow: 0px 4px 32px -8px rgba(0, 0, 0, 0.02);
	border-radius: 24px;
	margin-top: 36px;
	padding: 48px 40px;
}

.login-input-item {
	width: 100%;
	height: 50px;
	border-radius: 30px;
	border: 1px solid #DCDFE6;
	padding: 0 24px;
}

.login-input-item .ant-input {
	border: none;
	font-size: 16px;
}

.login-input-item .ant-input-affix-wrapper .ant-input:not(:first-child) {
	padding-left: 66px;
}

.login-input-item .mobile {
	width: 100%;
}

.login-input-item .code {
	width: 200px;
}

.login-input-item .ant-input-affix-wrapper .ant-input-prefix {
	color: #A6AEC2;
	font-size: 16px;
}

.login-input-item .ant-input:focus {
	border: none;
	box-shadow: none;
}

.login-btn .ant-btn {
	height: 50px;
	border-radius: 25px;
	font-size: 16px;
}
</style>
